<template>
  <el-card class="card" :bodyStyle="{ height: '100%' }">
    <div class="cardBody">
      <div
        style="
          display: flex;
          flex-direction: column;
          height: calc(100vh - 180px);
        "
      >
        <div style="display: flex; justify-content: space-between">
          <div style="display: flex">
            <div class="condition-item">
              <label class="fn-14">部门</label>
              <el-input
                v-model="searchName"
                placeholder="请输入部门名"
                autocomplete="off"
                size="small"
              ></el-input>
            </div>
            <div class="condition-item">
              <el-button
                v-if="search"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                size="small"
                >查询</el-button
              >
              <el-button
                v-if="search"
                type="info"
                icon="el-icon-refresh"
                @click="resetSearch"
                size="small"
                >重置</el-button
              >
            </div>
          </div>
          <div class="condition-item">
            <el-button
              v-if="addButton"
              type="success"
              icon="el-icon-plus"
              @click="handleAdd"
              size="small"
              >新增</el-button
            >
            <el-button
              v-if="deleteButton"
              type="danger"
              icon="el-icon-delete"
              size="small"
              @click="handleDelete"
              >删除</el-button
            >
          </div>
        </div>

        <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
          <el-table
            :data="deptList"
            v-loading="tableLoading"
            stripe
            border
            height="100%"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              fixed
              type="selection"
              width="50"
            ></el-table-column>
            <el-table-column
              fixed
              prop="name"
              label="部门名称"
            ></el-table-column>
            <el-table-column prop="code" label="部门编码" align="center"></el-table-column>
            <el-table-column prop="orgName" label="机构名称"></el-table-column>
            <el-table-column fixed="right" label="操作" width="260" align="center">
              <template slot-scope="scope">
                <div
                  v-if="view"
                  class="my-button"
                  @click="handleView(scope.row.id)"
                >
                  查看
                </div>
                <div
                  v-if="updateButton"
                  class="my-button-green"
                  @click="handleUpdate(scope.row.id)"
                >
                  修改
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div style="display: flex; justify-content: right">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>

        <el-dialog
          :title="
            dialogType === 1
              ? '新增部门'
              : dialogType === 2
              ? '查看部门'
              : '修改部门'
          "
          :visible.sync="dialogVisible"
          width="500px"
        >
          <el-form
            ref="deptForm"
            :model="deptForm"
            :rules="deptRules"
            label-width="80px"
          >
            <el-form-item label="部门名称" prop="name">
              <el-input
                type="text"
                v-model="deptForm.name"
                placeholder="请输入部门名称"
                :disabled="dialogType === 2"
              ></el-input>
            </el-form-item>
            <el-form-item label="部门编码" prop="code">
              <el-input
                type="text"
                v-model="deptForm.code"
                placeholder="请输入部门编码"
                :disabled="dialogType === 2"
              ></el-input>
            </el-form-item>
            <el-form-item label="机构" prop="orgId">
              <el-select
                v-model="deptForm.orgId"
                placeholder="请选择机构"
                :disabled="dialogType === 2"
                style="width: 100%"
              >
                <el-option
                  v-for="item in orgOptions"
                  :key="item.id"
                  :label="item.orgName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button
              v-if="dialogType !== 2"
              type="primary"
              @click="handleConfirm"
              :loading="confirmLoading"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </div>
    </div>
  </el-card>
</template>

<script>
import {
  queryDept,
  addDept,
  deleteDept,
  getDept,
  updateDept,
} from "@/api/dept";
import { queryOrgDict } from "@/api/org";
import { queryPageButton } from "@/api/permission";

export default {
  name: "DeptMgt",
  data() {
    const validateName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("部门名称不能为空"));
      } else {
        callback();
      }
    };
    const validateCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("部门编码不能为空"));
      } else {
        callback();
      }
    };
    const validateOrgId = (rule, value, callback) => {
      if (!value) {
        callback(new Error("机构不能为空"));
      } else {
        callback();
      }
    };
    return {
      searchName: "",
      deptList: [],
      tableLoading: false,
      tableSelection: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      dialogType: 1,
      dialogVisible: false,
      deptForm: {},
      deptRules: {
        name: [{ required: true, trigger: "blur", validator: validateName }],
        code: [{ required: true, trigger: "blur", validator: validateCode }],
        orgId: [{ required: true, trigger: "blur", validator: validateOrgId }],
      },
      orgOptions: [],
      confirmLoading: false,
      search: false,
      addButton: false,
      deleteButton: false,
      view: false,
      updateButton: false,
    };
  },
  created() {
    this.queryButton();
    this.query();
  },
  methods: {
    queryButton() {
      const data = 11;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "search") {
            this.search = true;
          }
          if (button.code === "add") {
            this.addButton = true;
          }
          if (button.code === "delete") {
            this.deleteButton = true;
          }
          if (button.code === "view") {
            this.view = true;
          }
          if (button.code === "update") {
            this.updateButton = true;
          }
        }
      });
    },
    query() {
      this.tableLoading = true;
      const data = {
        name: this.searchName,
      };

      queryDept(this.currentPage, this.pageSize, data).then((resp) => {
        this.deptList = resp.data.content;
        this.total = resp.data.totalElements;
        this.tableLoading = false;
      });
    },
    queryOrg() {
      queryOrgDict().then((resp) => {
        this.orgOptions = resp.data;
      });
    },
    handleSelectionChange(val) {
      this.tableSelection = val;
    },
    handleSearch() {
      this.currentPage = 1;
      this.query();
    },
    resetSearch() {
      this.searchName = "";
      this.currentPage = 1;
      this.query();
    },
    handleAdd() {
      this.queryOrg();
      this.deptForm = {};
      if (this.$refs.deptForm !== undefined) {
        this.$refs.deptForm.resetFields();
      }
      this.dialogType = 1;
      this.dialogVisible = true;
    },
    handleConfirm() {
      if (this.dialogType === 1) {
        this.add();
      } else if (this.dialogType === 3) {
        this.update();
      }
    },
    add() {
      this.$refs.deptForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          const data = this.deptForm;

          addDept(data)
            .then((resp) => {
              this.$notify({
                title: "新增成功",
                message: resp.message,
                type: "success",
              });
              this.confirmLoading = false;
              this.dialogVisible = false;
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {
              this.confirmLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleDelete() {
      if (this.tableSelection === null || this.tableSelection.length === 0) {
        this.$notify({
          title: "警告",
          message: "选择的部门不能为空",
          type: "warning",
        });
        return;
      }

      this.$confirm("此操作将删除该部门, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const data = [];
          for (const item of this.tableSelection) {
            data.push(item.id);
          }

          deleteDept(data)
            .then((resp) => {
              this.$notify({
                title: "删除成功",
                message: resp.message,
                type: "success",
              });
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },
    handleView(id) {
      const data = id;
      getDept(data)
        .then((resp) => {
          this.queryOrg();
          if (this.$refs.deptForm !== undefined) {
            this.$refs.deptForm.resetFields();
          }
          this.deptForm = resp.data;

          this.dialogType = 2;
          this.dialogVisible = true;
        })
        .catch((error) => {});
    },
    handleUpdate(id) {
      const data = id;
      getDept(data)
        .then((resp) => {
          this.queryOrg();
          if (this.$refs.deptForm !== undefined) {
            this.$refs.deptForm.resetFields();
          }
          this.deptForm = resp.data;

          this.dialogType = 3;
          this.dialogVisible = true;
        })
        .catch((error) => {});
    },
    update() {
      this.$refs.deptForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          const data = this.deptForm;

          updateDept(data)
            .then((resp) => {
              this.$notify({
                title: "修改成功",
                message: resp.message,
                type: "success",
              });
              this.confirmLoading = false;
              this.dialogVisible = false;
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {
              this.confirmLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query();
    },
  },
};
</script>

<style scoped>
.card {
  height: calc(100% - 4px);
}
.cardBody {
  height: calc(100% - 40px);
}
</style>
